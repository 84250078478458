export default {
  name: 'X11 Color Names',
  description: 'Named colors per the CSS3 specification',
  links: {
    'W3C Specification': 'https://www.w3.org/TR/css3-color/#svg-color'
  },
  colors: [
    // Pinks
    { name: 'pink', value: '#ffc0cb' },
    { name: 'lightpink', value: '#ffb6c1' },
    { name: 'hotpink', value: '#ff69b4' },
    { name: 'deeppink', value: '#ff1493' },
    { name: 'palevioletred', value: '#db7093' },
    { name: 'mediumvioletred', value: '#c71585' },
    // Reds
    { name: 'lightsalmon', value: '#ffa07a' },
    { name: 'salmon', value: '#fa8072' },
    { name: 'darksalmon', value: '#e9967a' },
    { name: 'lightcoral', value: '#f08080' },
    { name: 'indianred', value: '#cd5c5c' },
    { name: 'crimson', value: '#dc143c' },
    { name: 'firebrick', value: '#b22222' },
    { name: 'darkred', value: '#8b0000' },
    { name: 'red', value: '#ff0000' },
    // Oranges
    { name: 'orangered', value: '#ff4500' },
    { name: 'tomato', value: '#ff6347' },
    { name: 'coral', value: '#ff7f50' },
    { name: 'darkorange', value: '#ff8c00' },
    { name: 'orange', value: '#ffa500' },
    // Yellows
    { name: 'yellow', value: '#ffff00' },
    { name: 'lightyellow', value: '#ffffe0' },
    { name: 'lemonchiffon', value: '#fffacd' },
    { name: 'lightgoldenrodyellow', value: '#fafad2' },
    { name: 'papayawhip', value: '#ffefd5' },
    { name: 'moccasin', value: '#ffe4b5' },
    { name: 'peachpuff', value: '#ffdab9' },
    { name: 'palegoldenrod', value: '#eee8aa' },
    { name: 'khaki', value: '#f0e68c' },
    { name: 'darkkhaki', value: '#bdb76b' },
    { name: 'gold', value: '#ffd700' },
    // Browns
    { name: 'cornsilk', value: '#fff8dc' },
    { name: 'blanchedalmond', value: '#ffebcd' },
    { name: 'bisque', value: '#ffe4c4' },
    { name: 'navajowhite', value: '#ffdead' },
    { name: 'wheat', value: '#f5deb3' },
    { name: 'burlywood', value: '#deb887' },
    { name: 'tan', value: '#d2b48c' },
    { name: 'rosybrown', value: '#bc8f8f' },
    { name: 'sandybrown', value: '#f4a460' },
    { name: 'goldenrod', value: '#daa520' },
    { name: 'darkgoldenrod', value: '#b8860b' },
    { name: 'peru', value: '#cd853f' },
    { name: 'chocolate', value: '#d2691e' },
    { name: 'saddlebrown', value: '#8b4513' },
    { name: 'sienna', value: '#a0522d' },
    { name: 'brown', value: '#a52a2a' },
    { name: 'maroon', value: '#800000' },
    // Greens
    { name: 'darkolivegreen', value: '#556b2f' },
    { name: 'olive', value: '#808000' },
    { name: 'olivedrab', value: '#6b8e23' },
    { name: 'yellowgreen', value: '#9acd32' },
    { name: 'limegreen', value: '#32cd32' },
    { name: 'lime', value: '#00ff00' },
    { name: 'lawngreen', value: '#7cfc00' },
    { name: 'chartreuse', value: '#7fff00' },
    { name: 'greenyellow', value: '#adff2f' },
    { name: 'springgreen', value: '#00ff7f' },
    { name: 'mediumspringgreen', value: '#00fa9a' },
    { name: 'lightgreen', value: '#90ee90' },
    { name: 'palegreen', value: '#98fb98' },
    { name: 'darkseagreen', value: '#8fbc8f' },
    { name: 'mediumaquamarine', value: '#66cdaa' },
    { name: 'mediumseagreen', value: '#3cb371' },
    { name: 'seagreen', value: '#2e8b57' },
    { name: 'forestgreen', value: '#228b22' },
    { name: 'green', value: '#008000' },
    { name: 'darkgreen', value: '#006400' },
    // Cyans
    { name: 'aqua', value: '#00ffff' },
    { name: 'cyan', value: '#00ffff' },
    { name: 'lightcyan', value: '#e0ffff' },
    { name: 'paleturquoise', value: '#afeeee' },
    { name: 'aquamarine', value: '#7fffd4' },
    { name: 'turquoise', value: '#40e0d0' },
    { name: 'mediumturquoise', value: '#48d1cc' },
    { name: 'darkturquoise', value: '#00ced1' },
    { name: 'lightseagreen', value: '#20b2aa' },
    { name: 'cadetblue', value: '#5f9ea0' },
    { name: 'darkcyan', value: '#008b8b' },
    { name: 'teal', value: '#800080' },

    { name: 'lightsteelblue', value: '#b0c4de' },
    { name: 'powderblue', value: '#b0e0e6' },
    { name: 'lightblue', value: '#add8e6' },
    { name: 'skyblue', value: '#87ceeb' },
    { name: 'lightskyblue', value: '#87cefa' },
    { name: 'deepskyblue', value: '#00bfff' },
    { name: 'dodgerblue', value: '#1e90ff' },
    { name: 'cornflowerblue', value: '#6495ed' },
    { name: 'steelblue', value: '#4682b4' },
    { name: 'royalblue', value: '#4169e1' },
    { name: 'blue', value: '#0000ff' },
    { name: 'mediumblue', value: '#0000cd' },
    { name: 'darkblue', value: '#00008b' },
    { name: 'navy', value: '#000080' },
    { name: 'midnightblue', value: '#191970' },

    { name: 'lavender', value: '#e6e6fa' },
    { name: 'thistle', value: '#d8bfd8' },
    { name: 'plum', value: '#dda0dd' },
    { name: 'violet', value: '#ee82ee' },
    { name: 'orchid', value: '#da70d6' },
    { name: 'fuchsia', value: '#ff00ff' },
    { name: 'magenta', value: '#ff00ff' },
    { name: 'mediumorchid', value: '#ba55d3' },
    { name: 'mediumpurple', value: '#9370db' },
    { name: 'blueviolet', value: '#8a2be2' },
    { name: 'darkviolet', value: '#9400d3' },
    { name: 'darkorchid', value: '#9932cc' },
    { name: 'darkmagenta', value: '#8b008b' },
    { name: 'purple', value: '#800080' },
    { name: 'indigo', value: '#4b0082' },
    { name: 'darkslateblue', value: '#483d8b' },
    { name: 'slateblue', value: '#6a5acd' },
    { name: 'mediumslateblue', value: '#7b68ee' },

    { name: 'white', value: '#ffffff' },
    { name: 'snow', value: '#fffafa' },
    { name: 'honeydew', value: '#f0fff0' },
    { name: 'mintcream', value: '#f5fffa' },
    { name: 'azure', value: '#f0ffff' },
    { name: 'aliceblue', value: '#f0f8ff' },
    { name: 'ghostwhite', value: '#f8f8ff' },
    { name: 'whitesmoke', value: '#f5f5f5' },
    { name: 'seashell', value: '#fff5ee' },
    { name: 'beige', value: '#f5f5dc' },
    { name: 'oldlace', value: '#fdf5e6' },
    { name: 'floralwhite', value: '#fffaf0' },
    { name: 'ivory', value: '#fffff0' },
    { name: 'antiquewhite', value: '#faebd7' },
    { name: 'linen', value: '#faf0e6' },
    { name: 'lavenderblush', value: '#fff0f5' },
    { name: 'mistyrose', value: '#ffe4e1' },

    { name: 'gainsboro', value: '#dcdcdc' },
    { name: 'lightgray', value: '#d3d3d3' },
    { name: 'silver', value: '#c0c0c0' },
    { name: 'darkgray', value: '#a9a9a9' },
    { name: 'gray', value: '#808080' },
    { name: 'dimgray', value: '#696969' },
    { name: 'lightslategray', value: '#778899' },
    { name: 'slategray', value: '#708090' },
    { name: 'darkslategray', value: '#2f4f4f' },
    { name: 'black', value: '#000000' }
  ]
}
